<template>
  <PdfViewer />
</template>

<script>
import PdfViewer from "./components/PdfViewer";

export default {
  name: "App",

  components: {
    PdfViewer,
  },

  data: () => ({
    //
  }),
};
</script>
