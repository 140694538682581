<template>
  <cafe-pdf ref="pdf" @on-success="load" @on-scroll="scroll" :filePath="src">
    <template slot="header">
      <div data-app>
        <table-contents
          ref="tableContents"
          :toc-start-page="4"
          @change-page="pageChange"
          :src="src"
        ></table-contents>
        <v-toolbar width="100%">
          <v-app-bar-nav-icon
            @click.stop="$refs.tableContents.openDrawer"
          ></v-app-bar-nav-icon>

          <v-row no-gutters justify="space-between">
            <v-col cols="9">
              <v-toolbar-title>{{ title }}</v-toolbar-title></v-col
            >
            <v-col cols="3">
              <v-select
                v-model="scale"
                @change="setScale"
                :items="scales"
                hide-details
                label="Scale"
                single-line
              >
              </v-select> </v-col
          ></v-row>
        </v-toolbar>
      </div>
    </template>
    <template slot="footer">
      <div data-app>
        <v-row>
          <v-col>
            <v-btn :disabled="currentNum <= 1" @click="pre" block icon x-large>
              <v-icon> mdi-arrow-left-thin-circle-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn block plain class="mt-3" @click="pageChange(1)"
              >{{ currentNum }}/{{ pageNum }}</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              :disabled="currentNum >= pageNum"
              @click="next"
              block
              icon
              x-large
            >
              <v-icon> mdi-arrow-right-thin-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
  </cafe-pdf>
</template>

<script>
import cafePdf from "cafe-pdf";
import "cafe-pdf/package/cafe-pdf.css";
import TableContents from "./TableContents.vue";

export default {
  name: "app",
  data() {
    return {
      src: "/documents/calabar-handbook.pdf",
      pageNum: null,
      scale: "1",
      currentNum: 1,
      title: "Calabar eBook",
    };
  },
  components: { cafePdf, TableContents },
  computed: {
    scales() {
      return [
        { value: "auto", text: "auto" },
        { value: "page-actual", text: "actual" },
        { value: "page-fit", text: "fit" },
        { value: "page-width", text: "width" },
        { value: "0.50", text: "50%" },
        { value: "0.75", text: "75%" },
        { value: "1", text: "100%" },
        { value: "1.25", text: "125%" },
        { value: "1.50", text: "150%" },
        { value: "1.75", text: "175%" },
        { value: "2", text: "200%" },
        { value: "3", text: "300%" },
        { value: "4", text: "400%" },
      ];
    },
  },

  methods: {
    fileChanged() {
      this.file = this.src;
    },
    load(val) {
      this.pageNum = val;
    },
    setScale() {
      this.$refs.pdf.changeScale(this.scale);
    },
    scroll(val) {
      this.currentNum = val;
    },
    pre() {
      this.$refs.pdf.prePage();
    },
    next() {
      this.$refs.pdf.nextPage();
    },
    pageChange(val) {
      this.$refs.pdf.goToPage(val);
    },
    print() {
      this.$refs.pdf.printFile();
    },
  },
};
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
#app {
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
@media print {
  #app {
    display: none;
  }
}
</style>