<template>
  <v-navigation-drawer v-model="drawer" absolute temporary app>
    <v-toolbar width="100%">
      <v-toolbar-title>Menu</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click.stop="drawer = false" icon>
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>
    <v-list>
      <v-list-item link @click="pageChange(tocStartPage)">
        <v-list-item-content>
          <v-list-item-title>Table of Contents</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "TableContents",
  data: () => ({
    drawer: false,
    showLink: false,
  }),

  props: {
    tocStartPage: { type: Number, required: true },
    src: { type: String, required: true },
  },

  computed: {},
  watch: {
    drawer: function (val) {
      if (val) {
        this.showLink = true;
      }
    },
  },
  methods: {
    pageChange(evt) {
      this.$emit("change-page", evt);
      this.drawer = false;
    },
    openDrawer() {
      this.drawer = true;
    },
  },
};
</script>